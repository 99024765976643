import React, {Component} from "react";
//import ReactDOM from 'react-dom';
import { 
  Form, 
  Button, 
  Alert,
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";
import axios from "axios";
//import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import SectionHeading from "./sectionHeading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import OneLiner from './oneLiner';

export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendButton : {
        message: 'Send',
        disabled: false
      },
      alert:{
        success:false,
        error: false
      },
      //captchaToken:undefined,
      emailFocus:false
    };
  }
  
  resetForm() {
    this.setState({
      sendButton : {
        message: 'Send',
        disabled: false
      }
    });
  }
  
  componentDidMount() {
    //loadReCaptcha('6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4');
    //console.log("===ContactForm.componentDidMount()===")
  }
  
  // When the user clicks in the email/phone field
  handleEmailFocus() {
    this.setState({emailFocus:true})
    
    // Call the Google reCAPTCHA script
    // This starts a two minute ttl for the token
    // Called here instead of in handleSubmit because it needs time to return before submitting the form
  //  const recaptcha = 
  //    <ReCaptcha
  //      sitekey="6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4"
  //      action='contact_form_entry'
  //      verifyCallback={this.verifyCallback}
  //    />;
  //  ReactDOM.render(recaptcha,document.getElementById('contactForm'));
  }
  
//  // Save the reCAPTCHA token in the form state
//  verifyCallback = (callbackToken) => {
//    // Here you will get the final recaptchaToken!!!  
//    //console.log(recaptchaToken, "<= your recaptcha token")
//    this.setState({captchaToken:callbackToken});
//  }

  handleSubmit = async(event) => {
    event.preventDefault();
    this.setState({ 
      sendButton: { message: 'Sending...', disabled: true },
    });
    console.log(event);
    
    try {
      const params = {
        formCgFormId: '8dm1mlb',
        formEmail: event.target.formEmail.value, 
        formPhone: event.target.formPhone.value, 
        formWho: event.target.formWho.value, 
        formWhat: event.target.formWhat.value,
        //captchaToken: this.state.captchaToken
      };
      await axios.post(`https://3blwbf5x4f.execute-api.us-east-1.amazonaws.com/prod/contact`, params);
      console.log('success!');
      this.setState({ sendButton : { message: 'Sent!', disabled: true }, alert: {success: true, error:false}});
    }
    catch (err) {
      console.log('error!');
      console.log(err);
      // ToDo update message to user (use a bootstrap component) with an alternate contact method
      this.setState({ sendButton : { message: 'Oops!', disabled: true }, alert: {success:false, error: true}});
    }
    
    // The code below worked except it scrolled too far...
    //document.getElementById('sendAlert').scrollIntoView();
    
    // Reset send button
    setTimeout(function(){ this.resetForm(); }.bind(this), 5000);
  }
  
  render() {
    return (
      <div id="contactForm" className="cg-sectionLight">
        <div className="cg-section">
        
          <OneLiner/>
          {/* indentation problem here?? */}
        <div  style={{padding:'0.5rem 2rem 2rem 2rem', maxWidth:'40rem', margin:'2rem auto 0 auto'}}>
            <SectionHeading>Contact Us for a Quote</SectionHeading>
            <Container fluid>
              <Row>
                <Col md>
                  <Row>
                    <Col sm>
                      <p>It's easy to get started! Tell us about your project by <a href="mailto:mlb_contracting@yahoo.com" className='cg-linkDark'>email</a>
                      , <a href="tel:+13157946223" className='cg-linkDark'>phone</a>
                      , <a href="tel:+13157946223" className='cg-linkDark'>text</a> or by using this
                      form. <em>Contact us today.</em> We'll get back with you 
                      as soon as possible.</p>
                      <br/>
                    </Col>
                    <Col sm>
                      <dl>
                        <dt><FontAwesomeIcon icon={faPhoneAlt} /> Voice / Text</dt>
                        <dd><a href="tel:+13157946223" className='cg-linkDark'>(315)&nbsp;794&#8209;6223</a></dd>
                        <dt><FontAwesomeIcon icon={faEnvelope} /> Email</dt>
                        <dd><a href="mailto:mlb_contracting@yahoo.com" className='cg-linkDark'>mlb_contracting@yahoo.com</a></dd>
                      </dl>
                    </Col>
                  </Row>
                </Col>
                <Col md>
                  <Form onSubmit={event => this.handleSubmit(event)}>
                    <Form.Group controlId="formEmail">
                      <Form.Label srOnly='true'>Email</Form.Label>
                      <Form.Control placeholder="Email address" onFocus={() => this.handleEmailFocus()} onBlur={() => this.setState({emailFocus:false})} />
                      {this.state.emailFocus && 
                        <Form.Text className="text-muted">
                          We'll never share your email or phone number with anyone else.
                        </Form.Text>
                      }
                    </Form.Group>
                    
                    <Form.Group controlId="formPhone">
                      <Form.Label srOnly='true'>Phone</Form.Label>
                      <Form.Control placeholder="Phone number" onFocus={() => this.handleEmailFocus()} onBlur={() => this.setState({emailFocus:false})} />
                    </Form.Group>
                    
                    <Form.Group controlId="formWho">
                      <Form.Label srOnly='true'>Name</Form.Label>
                      <Form.Control placeholder="Your Name" />
                    </Form.Group>
                    
                    <Form.Group controlId="formWhat">
                      <Form.Label srOnly='true'>Comments</Form.Label>
                      <Form.Control as="textarea" rows="3" placeholder="How can we help you?" defaultValue={this.props.message}/>
                    </Form.Group>
                    
                    <Button type="submit" 
                        disabled={this.state.sendButton.disabled}
                        style={{ backgroundColor:'#f00', borderColor:'#ccc', color:'#fff' }}
                        >
                      {this.state.sendButton.message}
                    </Button>
                  </Form>
                  
                  <span id='sendAlert'>
                    <Alert variant='success' show={this.state.alert.success}>
                      Thanks for contacting us.  We'll get back to you soon!
                    </Alert>
                    <Alert variant='warning' show={this.state.alert.error}>
                      Oops! The message could not go though.  Please 
                      email <a href="mlb_contracting@yahoo.com">mlb_contracting@yahoo.com</a> or  
                      call <a href="tel:+13157946223">(315)&nbsp;794&#8209;6223</a>. 
                    </Alert>
                  </span>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
