import React from "react";
import { 
  Card,
  Container, 
  Row, 
  Col, 
} from "react-bootstrap";

export default () => (
<Container fluid>
  <Row>
    <Col style={{marginTop:'2rem'}}>
      <Card>
        <Card.Body style={{display:'flex', justifyContent: 'space-between'}}>
          <div>
            <img src='/logo.webp' style={{maxWidth:'100px', marginRight:'1.3rem'}} className='d-none d-md-block' alt='MLB logo'/>
          </div>
          <div>
            <img src='/logo.webp' style={{maxWidth:'100px', margin:'auto auto 1em auto'}} className='d-md-none'  alt='MLB logo'/>
            <div>
              House exteriors look bad after getting worn down by weather and time. 
              We refresh curb-appeal by installing high-quality roofing and siding. 
              When we finish, the owner feels they're coming home to a&nbsp;brand&nbsp;new&nbsp;house.
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>
);