import React from "react";
import Img from "gatsby-image";

export default ({img, imgStyle, style, thick}) => (
  <React.Fragment>
    <hr/>
    {img &&
        <Img fluid={img} className='cg-hrFill' imgStyle={imgStyle} style={style}/>
    }
    {thick &&
      <div className='cg-hrFill' style={{backgroundColor:'white', ...style}}>&nbsp;</div>
    }
    {(img || thick ) &&
        <hr/>
    }
  </React.Fragment>
);