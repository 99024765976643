import PropTypes from "prop-types"
import React from "react"
import {Button, Navbar,Nav, NavDropdown} from "react-bootstrap"

const Header = () => (
  <div>
    <header>
      <a href="#main" id='skipnav' tabIndex='-1'>Skip to main content</a>
      <Navbar expand="sm" collapseOnSelect  variant="light" bg="white" fixed='top'>
        <Navbar.Toggle  aria-controls="navbar-toggled"/>
        
        
        <Navbar.Collapse id="navbar-toggled">
          <Nav className="mr-auto">
            <Navbar.Brand href="/">
              <img
                src="/logo.webp"
                width="128"
                height="56"
                className="align-top d-inline-block"
                alt="MLB Contracting"
              />
            </Navbar.Brand>
            <Nav.Link href="/reviews">Reviews</Nav.Link >
            {/*<Nav.Link href="/portfolio">Portfolio</Nav.Link>*/}
            <NavDropdown title="Portfolio">
              <NavDropdown.Item href="/portfolio-roofing">Roofing</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-siding">Siding</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-kitchen">Kitchen</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-bathroom">Bathroom</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-construction">New Construction</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-decks">Decks</NavDropdown.Item>
              <NavDropdown.Item href="/portfolio-carpentry">Carpentry</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Services" id="navbar-dropdown-resources">
              <NavDropdown.Item href="/roofing-contractor">Roofing</NavDropdown.Item>
              <NavDropdown.Item href="/vinyl-siding-contractor">Siding</NavDropdown.Item>
              <NavDropdown.Item href="/kitchen-and-bathroom-contractor">Kitchen & Bathroom</NavDropdown.Item>
              <NavDropdown.Item href="/new-construction-contractor">New Construction</NavDropdown.Item>
              <NavDropdown.Item href="/deck-contractor">Decks</NavDropdown.Item>
              <NavDropdown.Item href="/carpentry-contractor">Carpentry</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand>
          <div id="cg-headerContact" className="cg-floatIn">
            <Button className="cg-dark" href="#contactForm">Get a Quote</Button>
            <a href="tel:+13157946223" className='cg-linkDark'>(315)&nbsp;794&#8209;6223</a>
            <a href="mailto:mlb_contracting@yahoo.com" className='cg-linkDark'>mlb_contracting@yahoo.com</a>
          </div>
        </Navbar.Brand>
      </Navbar>
    
    </header>
    <div className='cg-headerFog'>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
